<script setup lang="ts">
import type { ValidationError } from '~/types/validation_error'

definePageMeta({
  layout: 'auth',
})

const loginForm = ref({
  email: '',
  password: '',
})

const errors = ref<ValidationError | null>(null)

const login = () => {
  useAuth()
    .login(loginForm.value.email, loginForm.value.password)
    .catch((error) => {
      errors.value = error.data
    })
}
</script>

<template>
  <main class="bg-[url('/img/login.png')] bg-bottom bg-no-repeat bg-contain pb-96">
    <form
      class="p-5"
      @submit.prevent="login"
    >
      <div class="input mb-7">
        <label
          for="email"
          class="block mb-2 text-sm font-medium text-gray-900"
        >メールアドレス</label>
        <div class="relative">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
            <FaIcon
              icon="envelope"
              class="text-gray-500 dark:text-gray-500"
            />
          </div>
          <input
            id="email"
            v-model="loginForm.email"
            type="text"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full py-3 pl-11"
            placeholder="name@example.com"
          >
        </div>
        <p
          v-if="errors?.errors.email?.length"
          class="errors text-rose-800 text-xs mt-1.5"
        >
          {{ errors.errors.email[0] }}
        </p>
      </div>
      <!-- /.input -->
      <div class="input mb-10">
        <label
          for="password"
          class="block mb-2 text-sm font-medium text-gray-900"
        >パスワード</label>
        <div class="relative">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
            <FaIcon
              icon="lock"
              class="text-gray-500 dark:text-gray-500"
            />
          </div>
          <input
            id="password"
            v-model="loginForm.password"
            type="password"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full py-3 pl-11"
            placeholder="**************"
          >
        </div>
        <p
          v-if="errors?.errors.password?.length"
          class="errors text-rose-800 text-xs mt-1.5"
        >
          {{ errors.errors.email[0] }}
        </p>
      </div>
      <!-- /.input -->
      <div class="buttons mb-4">
        <p
          v-if="errors?.errors.message"
          class="errors text-rose-800 text-xs mb-4 text-center"
        >
          {{ errors.errors.message }}
        </p>
        <BaseButton
          color="rose"
          variant="solid"
          type="submit"
        >
          ログイン
        </BaseButton>
      </div>
      <p class="text-center">
        <NuxtLink
          to="/password-reset"
          class="underline"
        >
          パスワードを忘れた方はこちら
        </NuxtLink>
      </p>
    <!-- /.buttons -->
    </form>
  </main>
</template>
